import React from 'react';
import Header from '../components/Header/Header'
import GlobalStyle from "../GlobalStyles"
import Footer from "../components/Footer/Footer"
import { PageTitleWrapper, PageTitle } from "../components/PageTitle/PageTitle"
import ContentWithImage from "../components/ContentWithImage/ContentWithImage"
import { Paragraph, Image } from "../components/ContentWithImage/ContentWithImage"
import Helmet from "react-helmet"

const IndividualEntities = () => (
  <>
    <GlobalStyle />
    <Helmet>
      <meta name="viewport"
            content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0" />
      <meta http-equiv="X-UA-Compatible" content="ie=edge" />
      <meta charSet="utf-8" />
      <title>Kancelaria Merda</title>
      <link rel="canonical" href="https://kancelaria-merda.pl/" />
    </Helmet>
    <Header />
    <PageTitleWrapper>
      <PageTitle>Klienci indywidualni</PageTitle>
    </PageTitleWrapper>
    <ContentWithImage>
      <Paragraph>
        Kancelaria świadczy pomoc prawną dla Klientów indywidualnych we wszystkich dziedzinach życia
        gdzie wymagana jest wiedza prawnicza, gdzie Klient ma kontakt z organami administracji publicznej
        czy też sądami, a życzy on sobie pomocy profesjonalnego pełnomocnika. Radca prawny może
        Państwa reprezentować przed wszystkimi sądami w różnego rodzaju postępowaniach sądowych na
        każdym ich etapie. Tak samo jak przed wszystkimi organami administracji publicznej, m. in. Zakładem
        Ubezpieczeń Społecznych, Urzędem Skarbowym, Państwową Inspekcją Pracy, organami samorządu
        terytorialnego. Radca prawny pomoże również Państwu w ocenie umów, które planujecie Państwo
        zawrzeć, jak też sporządzi umowę, która w sposób należyty będzie chronić Państwa interesy.
        <br/><br/>
        Zakres spraw: cywilne, karne, spadkowe, gospodarcze, konsumenckie (relacje konsument –
        deweloper) rodzinne, rozwody, alimenty, pracownicze, zus-owskie, ubezpieczeniowe,
        administracyjne, budowlane, handlowe, wykroczeniowe.
      </Paragraph>
      <Image src="/IndividualEntitiesImage.png" />
    </ContentWithImage>
    <Footer />
  </>
)

export default IndividualEntities;